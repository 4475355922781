import React from 'react';
import NextLink from 'next/link';

interface ILink {
    href: string;
    className?: string;
}

// this component wraps next/link to simplify code.
const Link: React.FunctionComponent<ILink> = (props) => {
    return (
        <NextLink href={props.href} passHref>
            <a className={props.className}>
                {props.children}
            </a>
        </NextLink>
    );
};

export default Link;
