import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Link from '../../components/Link';

export const Navbar: React.FunctionComponent<{
    toggleSidebar?: () => void;
    userName: string;
}> = function ({ toggleSidebar, userName }) {

    const onBurgerClick: React.MouseEventHandler<HTMLAnchorElement> = function (e) {
        e.preventDefault();
        if (toggleSidebar) {
            toggleSidebar();
        }
    }

    return (
        <div className="container-fluid stacked-nav">
            <header className="row flex-centered flex-wrap justify-content-center justify-content-lg-between py-3 bg-white text-light">
                <Link className="isuzu-logo col-12 col-lg-3 flex-centered justify-content-center mb-2 mb-lg-0 text-decoration-none" href="/">
                    <img className="navbar-logo" src="/isuzu-logo.png" />
                </Link>

                <div className="col-auto flex-centered fs-5">
                    <ul className="nav col-auto flex-centered mb-lg-0 g-0">
                        <li className="d-lg-none">
                            <a href="#" className="nav-link" onClick={onBurgerClick}>
                                <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
                            </a>
                        </li>
                    </ul>

                    <h6 className="text-black-50 me-4 mb-0 text-truncate">Welcome, {userName}</h6>

                    <FontAwesomeIcon className="text-danger fs-6" icon={faSignOutAlt}></FontAwesomeIcon>
                    <Link className="text-decoration-none text-danger fs-6" href="/account/logout">Logout</Link>

                </div>
            </header>
        </div>
    );
};
