import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Link from '../../components/Link';
import NavMenu from './NavMenu';

interface ISubMenu {
    name: string,
    url: string,
    id: number,
}

export interface IMenu {
    name: string,
    icon: IconDefinition,
    subMenus: ISubMenu[],
    url?: string,
    id?: number
}

export const SideBar: React.FunctionComponent<{
    menus: IMenu[]
    display: boolean;
}> = function ({ menus, display }) {

    function sideBarClass(): string {
        let c = "sidebar d-flex flex-column";
        if (!display) {
            c += " sidebar-hide";
        } else {
            c += " sidebar-show";
        }

        return c;
    }

    // function renderMenus() {
    //     if (menus.length > 0) {
    //         return menus.map(Q => {
    //             if (typeof Q.url !== "undefined") {
    //                 return <Link key={Q.name} href={Q.url ?? ""} className="btn text-white d-block text-start fs-6 shadow-none mb-3" >
    //                     <div className="d-flex justify-content-between align-items-center">
    //                         <div>
    //                             <div className="cms-menu-icon d-inline-block">
    //                                 <FontAwesomeIcon icon={Q.icon}></FontAwesomeIcon>
    //                             </div>

    //                             {Q.name}
    //                         </div>
    //                     </div>
    //                 </Link>
    //             }

    //             return (
    //                 <NavMenu key={Q.name} menu={Q}></NavMenu>
    //             );
    //         })
    //     } else {
    //         return null;
    //     }
    // }

    return (
        <div className={sideBarClass()} >
            <ul className="d-flex flex-column p-1">
                {menus.map(Q => {
                    if (Q.subMenus.length === 0 && Q.url === undefined) {
                        return null;
                    }
                    if (typeof Q.url !== "undefined") {
                        return <Link key={Q.name} href={Q.url ?? ""} className="btn text-white d-block text-start fs-6 shadow-none mb-3" >
                            <div className="d-flex justify-content-between align-items-center fw-bold">
                                <div>
                                    <div className="cms-menu-icon d-inline-block">
                                        <FontAwesomeIcon icon={Q.icon}></FontAwesomeIcon>
                                    </div>

                                    {Q.name}
                                </div>
                            </div>
                        </Link>
                    }

                    return (
                        <NavMenu key={Q.name} menu={Q}></NavMenu>
                    );
                })}
            </ul>
        </div>
    );
}
