import { UserManager, WebStorageStateStore } from "oidc-client";
import { getAppSettings } from '../AppSettings';

export function UserManagerFactory(): UserManager {
    return new UserManager({
        authority: getAppSettings().ssoAuthority,
        client_id: getAppSettings().ssoClientId,
        redirect_uri: `${getAppSettings().websiteHost}/account/login-callback`,
        post_logout_redirect_uri: `${getAppSettings().websiteHost}/account/login-callback`,
        revokeAccessTokenOnSignout: true,
        response_type: 'code',
        scope: 'openid profile email',
        stateStore: new WebStorageStateStore({
            store: localStorage
        }),
        userStore: new WebStorageStateStore({
            store: localStorage
        }),
        silent_redirect_uri: `${getAppSettings().websiteHost}/account/login-silent-callback`
    });
}
