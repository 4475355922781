import { faChevronDown, faChevronRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import Link from '../../components/Link';
import { IMenu } from './Sidebar';

const NavMenu: React.FunctionComponent<{
    menu: IMenu
}> = (props) => {
    // router is used to check if current path is active
    const router = useRouter();
    // this ref saves the reference to collapse div
    const collapseEl = useRef<HTMLDivElement>(null);

    // bsCollapse ref saves bootstrap collapse object, so we can called it from a button
    const bsCollapse = useRef<bootstrap.Collapse>();

    // this state is used to save wether NavMenu is Active or not
    const [isActive, setActive] = useState(false);

    useEffect(() => {
        const subMenuCollapse = collapseEl.current;

        (async () => {
            let expand = false;

            // check if submenu active
            expand = isChildActive();

            const { Collapse } = await import('bootstrap');

            if (subMenuCollapse && !bsCollapse.current) {
                // initialize bootstrap collapse
                const collapse = new Collapse(subMenuCollapse, {
                    toggle: expand
                });

                // we need event listener so that setActive() will wait for the animation to complete
                subMenuCollapse.addEventListener('show.bs.collapse', handleActive)
                subMenuCollapse.addEventListener('hide.bs.collapse', handleInactive);

                bsCollapse.current = collapse;
                setActive(expand);
            }
        })();

        return () => {
            // remove event listener on unmount
            if (subMenuCollapse) {
                subMenuCollapse.removeEventListener('show.bs.collapse', handleActive)
                subMenuCollapse.removeEventListener('hide.bs.collapse', handleInactive);
            }
        }
    }, []);

    const toggleCollapse = (): void => {
        bsCollapse.current?.toggle();
    }

    const handleActive = (): void => {
        setActive(true);
    }

    const handleInactive = (): void => {
        setActive(false);
    }

    const isChildActive = () => {
        const activeMenu = props.menu.subMenus.find(Q => router.route.includes(Q.url));
        
        if(activeMenu !== undefined) {
            return true;
        }

        return false;
    }

    return (
        <div className="mb-3">
            <a className="btn text-white d-block text-start fs-6" onClick={() => toggleCollapse()}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="fw-bold">
                        <div className="cms-menu-icon d-inline-block">
                            <FontAwesomeIcon icon={props.menu.icon}></FontAwesomeIcon>
                        </div>
                        {props.menu.name}
                    </div>

                    <FontAwesomeIcon className="me-1" icon={isActive ? faChevronDown : faChevronRight}></FontAwesomeIcon>
                </div>
            </a>

            <div className="collapse ps-4 pe-0" ref={collapseEl}>
                {props.menu.subMenus.map(subMenu => {
                    return (
                        <Link key={subMenu.name} className="text-decoration-none text-white d-block mt-1 mb-2 d-flex" href={subMenu.url}>
                            <div className="d-inline-block cms-sub-menu">
                                {router.route.includes(subMenu.url) ?
                                    <FontAwesomeIcon className="me-2" size="xs" icon={faCircle} style={{color: "#8F8F8F"}}></FontAwesomeIcon>
                                    :
                                    <React.Fragment></React.Fragment>
                                }
                            </div>
                            <small className={router.route.includes(subMenu.url) ? "fw-bold" : ""}>
                                {subMenu.name}
                            </small>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default NavMenu;
