import { faChartLine, faCogs, faDatabase } from '@fortawesome/free-solid-svg-icons';
import Head from 'next/head';
import React from 'react';
import { createApiClientWithAuth } from '../../api/ClientFactory';
import { UserManagerFactory } from '../../services/UserManagerFactory';
import { Navbar } from './Navbar';
import { IMenu, SideBar } from './Sidebar';

const CMSLayout: React.FunctionComponent = (props) => {
    const [displaySidebar, setDisplaySidebar] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [userName, setUserName] = React.useState('');
    const [menus, setMenus] = React.useState<IMenu[]>([]);

    React.useEffect(() => {
        (async () => {
            const userManager = UserManagerFactory();
            const user = await userManager.getUser();

            if (!user) {
                await userManager.signinRedirect();
                return;
            }
            else {
                setUserName(user.profile.email ?? '');
                setLoading(false);
            }

            const client = await createApiClientWithAuth();

            const cmsMenu = await client.getCmsMenu();

            const listMenus: IMenu[] = [
                {
                    name: 'User Management',
                    icon: faCogs,
                    subMenus: [
                        {
                            id: 2,
                            name: 'Register',
                            url: '/user-management/register'
                        },
                        {
                            id: 3,
                            name: 'Role Page Mapping',
                            url: '/user-management/role-page-mapping'
                        },
                        {
                            id: 4,
                            name: 'Mobile Role Page Mapping',
                            url: '/user-management/mobile-apps-role-page-mapping'
                        }
                    ]
                },
                {
                    name: 'Master Data',
                    icon: faDatabase,
                    subMenus: [
                        {
                            id: 5,
                            name: 'User Role Mapping',
                            url: '/master-data/user-role-mapping'
                        },
                        {
                            id: 6,
                            name: 'Highlight',
                            url: '/master-data/highlight'
                        },
                        {
                            id: 7,
                            name: 'News',
                            url: '/master-data/news'
                        },
                        {
                            id: 8,
                            name: 'Series',
                            url: '/master-data/series'
                        },
                        {
                            id: 9,
                            name: 'Model',
                            url: '/master-data/model'
                        },
                        {
                            id: 10,
                            name: 'Specification Indicators',
                            url: '/master-data/specification-indicators'
                        },
                        {
                            id: 11,
                            name: 'Katalog Unit',
                            url: '/master-data/katalog-unit'
                        },
                        {
                            id: 12,
                            name: 'Unit Comparison',
                            url: '/master-data/unit-comparison'
                        },
                        {
                            id: 13,
                            name: 'Suggestion Category',
                            url: '/master-data/suggestion-category'
                        },
                        {
                            id: 14,
                            name: 'Unit Suggestion',
                            url: '/master-data/unit-suggestion'
                        },
                        {
                            id: 15,
                            name: 'FAQ (Sales Talk)',
                            url: '/master-data/faq'
                        },
                        {
                            id: 16,
                            name: 'Kategori User Guideline',
                            url: '/master-data/kategori-user-guideline'
                        },
                        {
                            id: 17,
                            name: 'User Guideline',
                            url: '/master-data/user-guideline'
                        },
                        {
                            id: 18,
                            name: 'Leasing Partner',
                            url: '/master-data/leasing-partner'
                        },
                        {
                            id: 19,
                            name: 'Leasing Package',
                            url: '/master-data/leasing-package'
                        },
                        {
                            id: 20,
                            name: 'Header Tracking',
                            url: '/master-data/header-tracking'
                        },
                        {
                            id: 21,
                            name: 'Detail Tracking',
                            url: '/master-data/detail-tracking'
                        },
                        {
                            id: 22,
                            name: 'Email Escalation Config',
                            url: '/master-data/email-escalation-config'
                        },
                    ]
                },
                {
                    id: 23,
                    name: 'Report Referral Code',
                    icon: faChartLine,
                    subMenus: [],
                    url: '/report/report-referral-code'
                }
            ]

            const menusResult = (listMenus.map(Q => {
                const filterMenus = Q
                filterMenus.subMenus = Q.subMenus.filter(Q =>
                    cmsMenu.includes(Q.id)
                )
                return Q
            }))

            if (cmsMenu.includes(1)) {
                menusResult.unshift(
                    {
                        name: 'Dashboard',
                        icon: faChartLine,
                        subMenus: [],
                        url: '/dashboard'
                    }
                )
            }

            setMenus(menusResult);
        })();
    }, []);

    return (
        <React.Fragment>
            <Head>
                <meta key="meta-charset" charSet="utf-8" />
                <meta key="meta-viewport" name="viewport" content="width=device-width, initial-scale=1" />
                <link key="favicon" rel="icon" href="/favicon.ico" />
            </Head>

            {isLoading === true
                ? <React.Fragment />
                : <div className="navbar-frame">
                    <Navbar userName={userName} toggleSidebar={() => setDisplaySidebar(!displaySidebar)}></Navbar>
                    <div className="sidebar-frame">
                        <SideBar menus={menus} display={displaySidebar}></SideBar>
                        <main className="container-fluid p-4">
                            {props.children}
                        </main>
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

export default CMSLayout;
